import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import logger from "../middlewares/logger-middleware";
import reducer from "./reducers/index";

function configureStore() {
  let enhancer = {};

  const middleware = [];

  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    middleware.push(logger);

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(applyMiddleware(thunk, ...middleware));
  } else {
    enhancer = applyMiddleware(thunk, ...middleware);
  }

  const store = createStore(reducer, enhancer);

  return {
    store,
    Provider,
  };
}
export default configureStore;
